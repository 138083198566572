<template>
    <form class="space-y-4 md:space-y-6" @submit.prevent="sendResetLink">
        <div>
            <label
                for="email"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Email
            </label>
            <input
                v-model="form.email"
                type="email"
                name="email"
                id="email"
                class="text-input"
                placeholder="email@ticto.com"
                required
            />
        </div>

        <submit-button :loading="loading" class="w-full">
            Enviar link de redefinição
        </submit-button>

        <div class="text-sm text-center">
            <router-link
                :to="{ name: 'login' }"
                class="text-primary-600 hover:underline dark:text-white"
            >
                Voltar ao login
            </router-link>
        </div>
    </form>
</template>

<script>
import SubmitButton from "../../components/forms/SubmitButton.vue";

export default {
    components: { SubmitButton },
    data() {
        return {
            form: {
                email: "",
            },
            loading: false,
        };
    },

    methods: {
        async sendResetLink() {
            try {
                this.loading = true;
                await axios.get("/sanctum/csrf-cookie");
                await axios.post("/api/forgot-password", this.form);
                
                this.$notify(
                    {
                        group: "success",
                        title: "Sucesso",
                        text: "Link de redefinição enviado para seu email",
                    },
                    4000
                );
                
                this.$router.push({ name: "login" });
            } catch (error) {
                if (error.response) {
                    this.$notify(
                        {
                            group: "error",
                            title: "Erro",
                            text: error.response.data.message,
                        },
                        4000
                    );
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script> 