<template>
    <filters
        class="my-3"
        v-model="filters"
        @fetch="events = []; next_page_url = false; fetch();"
        :loading="loading"
        date-filter
        :default-dates="defaultDates"
        search-filter
        status-filter
        :statuses="[
            { value: true, label: 'Limpo' },
            { value: false, label: 'Bloqueado' },
        ]"
        search-label="Filtre fingerprint, IP, cidade, dispositivo, etc..."
    />

    <events-chart
        :dates="aggs"
        :loading="loading"
        class="mt-4"
    />

    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="[
            'Data',
            'Análises',
            'Produto',
            'Fingerprint',
            'Device',
            'Ip',
            'Email',
            'VisitorId'
        ]"
        @fetch="fetch"
    >
        <tr v-for="event in events">
            <td class="table-cell-sm">
                {{ new Date(event.created_at).toLocaleString("pt-BR") }}
            </td>
            <td class="table-cell-sm">
                <div class="flex items-center space-x-2">
                    <div :class="[event.passed ? 'bg-green-400' : 'bg-red-400', 'w-3 h-3 rounded-full']"></div>
                    <span class="dark:text-white ">{{ event.passed  ? 'Limpo' : ''}}</span>

                    <span v-if="event.failed_check">{{event.failed_check}}</span>

                    <cards-icon v-if="event.failed_check && event.failed_check === 'VisitorCards'" :cards="cards"/>

                    <!-- <router-link
                        v-for="affiliate in event.affiliates"
                        v-tooltip="'Afiliado :' + affiliate.code"
                        :to="{
                             name: 'user.trackings',
                             params: {
                                 user_id: affiliate.user_id
                             }
                         }"
                        class="bg-purple-500 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white uppercase"
                    >
                        {{ affiliate.code }}
                    </router-link> -->
                </div>
            </td>
            <td class="table-cell-sm">
                <div class="flex space-x-2">
                     <router-link
                         v-if="event.product"
                         v-tooltip="event.product.name"
                         :to="{
                             name: 'product',
                             params: {
                                 product_id: event.product.id
                             }
                         }"
                         class="bg-purple-500 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white"
                     >
                        {{ event.product.name.split(' ').map(n => n[0]).join('') }}
                     </router-link>

                    <span
                        v-if="event.offer"
                        v-tooltip="event.offer.code"
                        class="bg-indigo-500 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white"
                     >
                        {{ event.offer.code }}
                     </span>
                </div>
            </td>
            <td class="table-cell-sm">
                  <span
                      v-tooltip="event.cookie"
                      class="truncate w-12 block"
                  >
                    {{ event.cookie }}
                </span>
            </td>
            <td class="table-cell-sm">
                <div class="flex flex-col">
                    <div class="flex space-x-2" v-if="event.visitor_data">
                        <browser-icon :browser="event.visitor_data.browserDetails.browserName"/>
                        <span>{{ event.visitor_data.browserDetails.browserFullVersion }}</span>
                    </div>
                    <div class="flex space-x-2" v-if="event.visitor_data">
                        <os-icon :os="event.visitor_data.browserDetails.os"/>
                        <span>{{ event.visitor_data.browserDetails.osVersion }}</span>
                    </div>
                    <div
                        class="flex space-x-1"
                        v-if="event.visitor_data"
                    >
                        <span class="truncate">{{ event.visitor_data.ipLocation.country.code }}: </span>
                        <span
                            class="truncate w-32 block"
                            v-if="event.visitor_data.ipLocation.city"
                        >
                            {{ event.visitor_data.ipLocation.city.name }}
                        </span>
                    </div>
                </div>
            </td>
            <td class="table-cell-sm">
                  <span
                      v-tooltip="event.ip"
                      class="truncate w-32 block"
                  >
                    {{ event.ip }}
                </span>
            </td>
            <td class="table-cell-sm">
                {{ event.email }}
            </td>
            <td class="table-cell-sm">
                {{ event.visitorId }}
            </td>
        </tr>
    </table-component>
</template>

<script>
    import Filters from "../../components/layouts/Filters.vue";
    import TableComponent from "../../components/layouts/table/TableComponent.vue";
    import EventsChart from "../../components/antifraud/EventsChart.vue";
    import BrowserIcon from "../../components/layouts/antifraud/BrowserIcon.vue";
    import OsIcon from "../../components/layouts/antifraud/OsIcon.vue";
    import CardsIcon from "../../components/layouts/antifraud/CardsIcon.vue";

    export default {
        components: {CardsIcon, OsIcon, BrowserIcon, EventsChart, TableComponent, Filters},
        data() {
            return {
                defaultDates: [
                    moment().subtract(1, 'month').format('DD/MM/YYYY'),
                    moment().format('DD/MM/YYYY'),
                ],
                loading: false,
                events: [],
                next_page_url: false,
                pagination: {
                    total: 0,
                    per_page: 35,
                    current_page: 1,
                    last_page: 11,
                    from: 1,
                    to: 11
                },
                aggs: {
                    day: [],
                    browser: {},
                    city: {},
                    ip: {},
                    device: {}
                },
                filters: {
                    text: '',
                    status: [],
                    payment_method: []
                },
            }
        },

        mounted() {
            this.fetch();
        },

        methods: {
            fetch() {
                this.loading = true;

                const params = {
                    filter: this.filters,
                    page: this.pagination.current_page
                };

                axios.get('/api/antifraud', { params })
                    .then(({ data }) => {
                        // Atualiza os eventos com os items do hits
                        this.events = data.hits.hits;
                        
                        // Atualiza agregações
                        this.aggs = {
                            ...this.aggs,
                            day: data.aggregations.day.buckets,
                            total_count: data.aggregations.total_count,
                            by_failed_check: data.aggregations.by_failed_check.buckets,
                            checks: data.aggregations.checks.buckets
                        };

                        // Atualiza paginação
                        this.pagination = {
                            ...this.pagination,
                            total: data.hits.total.value,
                            from: (this.pagination.current_page - 1) * this.pagination.per_page + 1,
                            to: Math.min(this.pagination.current_page * this.pagination.per_page, data.hits.total.value),
                            last_page: Math.ceil(data.hits.total.value / this.pagination.per_page)
                        };

                        // Calcula se existe próxima página
                        this.next_page_url = this.pagination.current_page < this.pagination.last_page;
                        
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error('Erro ao carregar dados:', error);
                        this.loading = false;
                    });
            }
        }
    }
</script>
