<template>
    <form class="space-y-4 md:space-y-6" @submit.prevent="resetPassword">
        <div>
            <label
                for="email"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Email
            </label>
            <input
                v-model="form.email"
                type="email"
                name="email"
                id="email"
                class="text-input"
                placeholder="email@ticto.com"
                required
            />
        </div>

        <div>
            <label
                for="password"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Nova Senha
            </label>
            <input
                v-model="form.password"
                type="password"
                name="password"
                id="password"
                class="text-input"
                placeholder="••••••••"
                required
            />
        </div>

        <div>
            <label
                for="password_confirmation"
                class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
            >
                Confirmar Nova Senha
            </label>
            <input
                v-model="form.password_confirmation"
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                class="text-input"
                placeholder="••••••••"
                required
            />
        </div>

        <submit-button :loading="loading" class="w-full">
            Redefinir Senha
        </submit-button>

        <div class="text-sm text-center">
            <router-link
                :to="{ name: 'login' }"
                class="text-primary-600 hover:underline dark:text-white"
            >
                Voltar ao login
            </router-link>
        </div>
    </form>
</template>

<script>
import SubmitButton from "../../components/forms/SubmitButton.vue";

export default {
    components: { SubmitButton },
    data() {
        return {
            form: {
                token: this.$route.params.token,
                email: "",
                password: "",
                password_confirmation: "",
            },
            loading: false,
        };
    },

    methods: {
        async resetPassword() {
            try {
                this.loading = true;
                await axios.get("/sanctum/csrf-cookie");
                await axios.post("/api/reset-password", this.form);
                
                this.$notify(
                    {
                        group: "success",
                        title: "Sucesso",
                        text: "Senha redefinida com sucesso",
                    },
                    4000
                );
                
                this.$router.push({ name: "login" });
            } catch (error) {
                if (error.response) {
                    if (error.response.data.errors) {
                        console.log(error.response.data.errors);
                        this.$notify(
                            {
                                group: "error",
                                title: "Erro",
                                text: error.response.data.errors[0],
                            },
                            4000
                        );
                    }
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script> 