<template>
    <h3 class="my-2 font-medium font-bold text-gray-400 text-lg">
        Clear Sale - Logs
    </h3>
    <div class="flex flex-col dark:text-white">
        <div>
            <div
                class="min-w-0 w-full h-50 rounded-lg overflow-hidden bg-white dark:bg-gray-800 mb-4 flex drop-shadow-sm p-5"
                v-if="order"
            >
                <ol class="relative border-l border-gray-200 dark:border-gray-700">
                    <li class="mb-10 ml-4" v-for="log in order.clear_sale_logs">
                        <div
                            class="absolute w-3 h-3 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900"
                            :class="{
                                'bg-green-500': ['response'].indexOf(log.type) !== -1,
                                'bg-red-600': ['error'].indexOf(log.type) !== -1,
                                'bg-blue-500': ['info'].indexOf(log.type) !== -1,
                            }"
                        ></div>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200"
                        >
                            {{ capitalizeFirstLetter(log.type) }}
                        </p>
                        <p
                            v-for="data in parseLog(log)"
                            class="text-base font-normal text-gray-500 dark:text-gray-200 flex flex-wrap"
                        >
                            <b>{{ data.name }}</b>
                            <span>{{ data.value }}</span>
                        </p>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200 flex flex-wrap"
                        >
                            <ClockIcon class="w-5 mr-1"></ClockIcon>
                            {{ log.created_at }}
                        </p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import HelperMixin from "../../mixins/HelperMixin.vue";
import Status from "../../components/layouts/Status.vue";
import { ClockIcon, DocumentMagnifyingGlassIcon } from "@heroicons/vue/24/outline";

export default {
    props: ["order"],
    mixins: [HelperMixin],
    components: {Status, ClockIcon, DocumentMagnifyingGlassIcon},
    data() {
        return {};
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        parseLog(log) {
            let columns = [];

            if (log.type === 'response') {
                if (log.data.packageID) {
                    columns.push({
                        name: 'Package ID: ',
                        value: log.data.packageID,
                    });
                }

                if (log.data.Message) {
                    columns.push({
                        name: 'Error Message: ',
                        value: log.data.Message,
                    });
                }

                if (log.data.orders) {
                    columns.push({
                        name: 'Code: ',
                        value: log.data.orders[0].code,
                    });
                    columns.push({
                        name: 'Score: ',
                        value: log.data.orders[0].score,
                    });
                    columns.push({
                        name: 'Status: ',
                        value: log.data.orders[0].status,
                    });
                }
            }

            if (log.type === 'info') {
                Object.keys(log.data).forEach(key => {
                    columns.push({
                        name: `${this.capitalizeFirstLetter(key)}: `,
                        value: log.data[key]
                    });
                });
            }

            return columns;
        },
    },
};
</script>
