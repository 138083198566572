<template>
    <div
        class="overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="mx-auto w-full rounded-2xl">
                <Disclosure v-slot="{ open }">
                    <DisclosureButton
                        class="flex w-full justify-between rounded-lg bg-gray-800 px-4 py-2 text-left text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                    >
                        <span>Administrativo</span>
                        <ChevronUpIcon
                            :class="open ? 'rotate-180 transform' : ''"
                            class="h-5 w-5 text-white"
                        />
                    </DisclosureButton>
                    <DisclosurePanel
                        class="px-1 pb-2 pt-4 text-sm text-gray-500"
                    >
                        <form-toggle
                            v-model="admin_panel"
                            :loading="loading"
                            label="Acesso Administrativo"
                        >
                        </form-toggle>
                        <div v-for="role in roles">
                                <form-toggle
                                    v-model="role.has"
                                    :loading="loading"
                                    :label="role.name"
                                />
                        </div>
                        <div v-if="admin_panel">
                            <p
                                class="text-md mb-5 border-b border-indigo-500 pb-2 text-white"
                            >
                                Usuários
                            </p>
                            <button
                                @click.prevent="toggleAll"
                                :disabled="loading"
                                type="submit"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="mx-auto mb-3 inline-flex items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                            >
                                Marcar Todos
                            </button>
                            <div class="grid grid-cols-3">
                                <div v-for="permission in permissions">
                                    <form-toggle
                                        v-model="permission.has"
                                        :loading="loading"
                                        :label="permission.title"
                                    ></form-toggle>
                                </div>
                            </div>
                            <button
                                @click.prevent="updatePermissions"
                                :disabled="loading"
                                type="submit"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="mx-auto inline-flex items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                            >
                                <svg
                                    v-if="loading"
                                    class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Salvar
                            </button>
                        </div>
                    </DisclosurePanel>
                </Disclosure>
                <Disclosure as="div" class="mt-2" v-slot="{ open }">
                    <DisclosureButton
                        class="flex w-full justify-between rounded-lg bg-gray-800 px-4 py-2 text-left text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                    >
                        <span>Produtor</span>
                        <ChevronUpIcon
                            :class="open ? 'rotate-180 transform' : ''"
                            class="h-5 w-5 text-white"
                        />
                    </DisclosureButton>
                    <DisclosurePanel
                        class="flex flex-col px-4 pb-2 pt-4 text-sm text-gray-500"
                    >
                        <form-toggle
                            v-model="is_producer"
                            :loading="loading"
                            label="Cria Produtos ?"
                        ></form-toggle>

                        <submit-button
                            @click.prevent="updateProducer"
                            :loading="loading"
                        >
                            Salvar
                        </submit-button>
                    </DisclosurePanel>
                </Disclosure>
                <Disclosure as="div" class="mt-2" v-slot="{ open }">
                    <DisclosureButton
                        class="flex w-full justify-between rounded-lg bg-gray-800 px-4 py-2 text-left text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
                    >
                        <span>Outras</span>
                        <ChevronUpIcon
                            :class="open ? 'rotate-180 transform' : ''"
                            class="h-5 w-5 text-white"
                        />
                    </DisclosureButton>
                    <DisclosurePanel
                        class="flex flex-col px-4 pb-2 pt-4 text-sm text-gray-500"
                    >
                        <button
                            @click.prevent="toggleAllOtherPermissions"
                            :disabled="loading"
                            type="submit"
                            :class="{ 'cursor-not-allowed': loading }"
                            class="mb-3 inline-flex max-w-[195px] items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                        >
                            Marcar/Desmarcar Todos
                        </button>
                        <p
                            class="text-md mb-5 border-b border-indigo-500 pb-2 text-white"
                        >
                            Capsul
                        </p>
                        <div class="grid grid-cols-3">
                            <div v-for="permission in other_permissions">
                                <form-toggle
                                    v-if="permission.name.includes('capsul')"
                                    v-model="permission.has"
                                    :loading="loading"
                                    :label="permission.title"
                                ></form-toggle>
                            </div>
                        </div>

                        <p
                            class="text-md mb-5 border-b border-indigo-500 pb-2 text-white"
                        >
                            ViewAI
                        </p>
                        <div class="grid grid-cols-3">
                            <div v-for="permission in other_permissions">
                                <form-toggle
                                    v-if="permission.name.includes('viewAI')"
                                    v-model="permission.has"
                                    :loading="loading"
                                    :label="permission.title"
                                ></form-toggle>
                            </div>
                        </div>

                        <p
                            class="text-md mb-5 border-b border-indigo-500 pb-2 text-white"
                        >
                            Automações do WhatsApp
                        </p>
                        <div class="grid grid-cols-3">
                            <div v-for="permission in other_permissions">
                                <form-toggle
                                    v-if="
                                        permission.name.includes(
                                            'whatsapp_automations',
                                        )
                                    "
                                    v-model="permission.has"
                                    :loading="loading"
                                    :label="permission.title"
                                ></form-toggle>
                            </div>
                        </div>

                        <button
                            @click.prevent="updateOtherPermissions"
                            :disabled="loading"
                            type="submit"
                            :class="{ 'cursor-not-allowed': loading }"
                            class="inline-flex max-w-[70px] items-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                        >
                            <svg
                                v-if="loading"
                                class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    class="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                ></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            Salvar
                        </button>
                    </DisclosurePanel>
                </Disclosure>
            </div>
        </div>
    </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/20/solid";
import FormToggle from "../../../components/forms/FormToggle.vue";
import SubmitButton from "../../../components/forms/SubmitButton.vue";
export default {
    components: {
        SubmitButton,
        FormToggle,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronUpIcon,
    },
    props: ["user"],

    data() {
        return {
            watch: false,
            loading: false,
            admin_panel: false,
            is_producer: false,
            roles: [],
            permissions: [],
            other_permissions: [],
        };
    },

    watch: {
        admin_panel(val) {
            if (this.watch && !this.loading) {
                this.toggleAdminAccess(val);
            }
        },
    },

    mounted() {
        this.fetch();
    },

    methods: {
        toggleAll() {
            this.permissions.map((permission) => (permission.has = true));
        },
        toggleAllOtherPermissions() {
            this.other_permissions.map(
                (permission) => (permission.has = !permission.has),
            );
        },
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id + "/permission")
                .then(({ data }) => {
                    this.other_permissions = data.other_permissions;
                    this.permissions = data.permissions;
                    this.admin_panel = data.admin_panel;
                    this.roles = data.roles;
                    this.is_producer = data.is_producer;
                    this.loading = false;
                    this.$nextTick(() => {
                        this.watch = true;
                    });
                });
        },
        updateProducer() {
            this.loading = true;
            axios
                .post("/api/user/" + this.user.id + "/permission/producer", {
                    permission: this.is_producer,
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.$notify(
                        {
                            group: "success",
                            text: "Salvo com sucesso!",
                        },
                        4000,
                    );
                })
                .catch(({ response }) => {
                    this.loading = false;
                    this.is_producer = !this.is_producer;
                    this.$notify(
                        {
                            group: "error",
                            title: "Error",
                            text: response.data.message,
                        },
                        4000,
                    );
                });
        },
        toggleAdminAccess() {
            this.loading = true;
            this.watch = false;
            axios
                .post("/api/user/" + this.user.id + "/permission/admin", {
                    permission: this.admin_panel,
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.$nextTick(() => {
                        this.watch = true;
                    });
                })
                .catch(({ response }) => {
                    this.loading = false;
                    this.admin_panel = !this.admin_panel;
                    this.$nextTick(() => {
                        this.watch = true;
                    });
                    this.$notify(
                        {
                            group: "error",
                            title: "Error",
                            text: response.data.message,
                        },
                        4000,
                    );
                });
        },
        updatePermissions() {
            this.loading = true;
            axios
                .put("/api/user/" + this.user.id + "/permission", {
                    permissions: this.permissions,
                    roles: this.roles,
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.$nextTick(() => {
                        this.watch = true;
                    });
                })
                .catch(({ response }) => {
                    this.loading = false;
                });
        },
        updateOtherPermissions() {
            this.loading = true;
            axios
                .put("/api/user/" + this.user.id + "/permission/other", {
                    other_permissions: this.other_permissions,
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.$nextTick(() => {
                        this.watch = true;
                    });
                })
                .catch(() => (this.loading = false));
        },
    },
};
</script>
