<template>
    <transition-root :show="true" as="template">
        <dialog-headless
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeModal()"
        >
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity" />
                </transition-child>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div class="inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div class="sm:flex sm:items-start">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <dialog-title as="h3" class="text-lg leading-6 font-medium text-white">
                                    Enviar Mensagem WhatsApp
                                </dialog-title>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex flex-col">
                            <div class="grid sm:grid-cols-1">
                                <label for="phone_number" class="text-white">Número de telefone</label>
                                <input
                                    type="text"
                                    id="phone_number"
                                    v-model="phoneNumber"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Número de telefone"
                                    required
                                />
                            </div>
                            <div class="mt-3">
                                <label for="message" class="text-white">Mensagem</label>
                                <div class="bg-white">
                                    <QuillEditor
                                        id="message"
                                        :toolbar="['bold', 'italic', 'underline']"
                                        v-model:content="message"
                                        contentType="html"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="mt-3 grid sm:grid-cols-2 gap-5">
                                <button
                                    @click="sendMessage"
                                    type="button"
                                    class="mb-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm"
                                >
                                    Enviar
                                </button>
                                <button
                                    type="button"
                                    class="mb-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="closeModal()"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        QuillEditor,
    },
    data() {
        return {
            phoneNumber: '',
            message: '',
        };
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        sendMessage() {
            this.$emit('sendMessage', this.phoneNumber, this.message);
        },
    },
};
</script>
