<template>
    <div class="flex flex-col dark:text-white mt-4">
        <filters
            :filters="filters"
            :loading="loading"
            v-model="filters"
            @fetch="fetch"
            @clearFilters="clearFilters"
            date-filter
            search-filter
            search-label="Filtre pelo nome do favorecido, do usuário ou e-mail"
            status-filter
            :default-status="['pending']"
            :statuses="[
                { value: 'pending', label: 'Pendente' },
                { value: 'approved', label: 'Aprovado' },
                { value: 'reproved', label: 'Reprovado' },
            ]"
            type-filter
            :types="[
                { value: 'checking', label: 'Conta Corrente' },
                { value: 'saving', label: 'Conta Poupança' }
            ]"
            person-type-filter
            :person-types="[
                { value: 'person', label: 'Física' },
                { value: 'company', label: 'Jurídica' }
            ]"
        />

        <div class="mb-3 flex">
            <div class="ml-auto flex items-center">
                <button
                    class="btn-primary-sm ml-auto mr-2"
                    :disabled="loading"
                    @click.prevent="exportBankAccounts"
                >
                    <ArrowDownTrayIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                        aria-hidden="true"
                    />
                    Exportar
                </button>

                Exibindo {{ pagination.from }} a {{ pagination.to }} registros
                de {{ pagination.total }}
            </div>
        </div>

        <table-component
            :loading="loading"
            :pagination="pagination"
            @fetch="fetch"
            :headers="[
                '',
                'Nome do Usuário',
                'Nome do Favorecido',
                'Tipo de Pessoa',
                'Documento',
                'Tipo de Recebimento',
                'Dados para Recebimento',
                'Cadastrado em',
                'Status',
            ]"
        >
            <tr v-for="bankAccount in bankAccounts">
                <td class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200">
                    <div
                        @click.prevent="manage(bankAccount)"
                        class="text-indigo-400 hover:underline hover:text-indigo-700 hover:cursor-pointer"
                    >
                        #{{ bankAccount.id }}
                    </div>
                </td>

                <td class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200">
                    {{ bankAccount.user.name }}
                </td>
                <td class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200">
                    {{ bankAccount.holder_name }}
                </td>
                <td class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200">
                    {{ bankAccount.user.type === 'company' ? 'Jurídica' : 'Física' }}
                </td>
                <td class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200">
                    {{ bankAccount.user.type === 'company' ? formatCnpj(bankAccount.user.cnpj) : formatCpf(bankAccount.user.cpf) }}
                </td>
                <td class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200">
                    {{ bankAccount.receiver_type === 'pix' ? 'Chave Pix' : (bankAccount.type === 'checking' ? 'Conta Corrente' : 'Conta Poupança') }}
                </td>
                <td v-if="bankAccount.receiver_type === 'pix'" class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200 truncate max-w-[220px]">
                    <span @click="copyToClipboard(bankAccount.pix_key)" class="hover:cursor-pointer">
                        Chave: {{ bankAccount.pix_key }}
                    </span>
                </td>
                <td v-else class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200 ">
                    <span>Banco: {{ bankAccount?.bank?.name }}</span><br/>
                    <span>Agência: {{ bankAccount.branch_number }} </span><br/>
                    <span>Conta: {{ bankAccount.account_number }} - {{bankAccount.account_check_digit }}</span>
                </td>
                <td class="border-b border-slate-100 dark:border-slate-700 p-3  text-slate-500 dark:text-slate-200">
                    {{bankAccount.created_at}}
                </td>
                <td
                    class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200 items-center justify-center"
                >
                    <div
                        class="text-sm font-medium  px-2.5 py-1.5 rounded w-full text-center"
                        :class="getStatusClass(bankAccount.status)"
                    >
                        {{ getStatusName(bankAccount.status) }}
                    </div>
                </td>
            </tr>
        </table-component>
    </div>
</template>

<script>
import VueSelect from "../../components/forms/VueSelect.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import { mapWritableState, mapActions } from 'pinia';
import { Store } from '../../stores/store.js';
import HelperMixin from "../../mixins/HelperMixin.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import Filters from "../../components/layouts/Filters.vue";
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import { useToast } from "vue-toastification";

export default {
    components: {ArrowDownTrayIcon, Filters, TableComponent, VueSelect, Pagination },
    mixins: [HelperMixin],

    setup() {
        const toast = useToast();
        return { toast };
    },

    data() {
        return {
            loading: false,
            next_page_url: false,
            bankAccounts: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11
            },
            filters: {
                text: '',
                date: [],
                status: ['pending'],
                types: [],
                person_types: [], // Adicionado para armazenar o filtro de tipo de pessoa
            },
            showTooltip: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ['storeBankAccount']),
    },

    mounted() {
        this.storeProduct = false;
        let storageFilters = localStorage.getItem('bank_accounts_filter');
        if (storageFilters) {
            this.filters = JSON.parse(storageFilters);
        }
        this.filters.status = ['pending'];
        this.fetch();
    },

    methods: {
        exportBankAccounts() {
            this.loading = true;
            const params = this.filters;

            axios
                .get("/api/bank-account/export/csv", {
                    params: {
                        ...params,
                    },
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.toast.success(
                        "Exportação iniciada com sucesso, você receberá o relatório por e-mail em alguns minutos.",
                    );
                });
        },
        clearFilters() {
            this.bankAccounts = [];
            this.filters.status = [];
            this.filters.types = [];
            this.filters.text = '';
            this.next_page_url = false;
            this.fetch();
        },
        manage(bankAccount) {
            this.storeBankAccount = bankAccount;
            this.$router.push({
                name: 'finnancial.bank-account', params: { account_id: bankAccount.id }
            });
        },
        fetch() {
            this.loading = true;

            localStorage.setItem('bank_accounts_filter', JSON.stringify(this.filters));

            const params = this.filters;
            params['page'] = this.pagination.current_page;

            axios.get('/api/bank-account', {
                params: params
            }).then(({data}) => {
                this.bankAccounts = data.data;

                this.pagination = {
                    ...this.pagination,
                    ...data
                };

                this.next_page_url = data.next_page_url;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                // this.$toast.error(error.response.data.message);
            });
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.toast.success("Chave Pix copiada para a área de transferência.");
            }).catch(err => {
                this.toast.error("Falha ao copiar a chave Pix.");
            });
        }
    }
}
</script>
