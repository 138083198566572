<template>
    <div class="my-5">
        <h3 class="text-lg leading-6 font-medium dark:text-white mb-5">
            Credenciais do Z-API
        </h3>
        <div class="grow">
            <SvgLoader v-if="this.loading" />
            <form @submit.prevent="submit" v-else>
                <div class="grid sm:grid-cols-2 sm:gap-10">
                    <div class="grid sm:grid-cols-1">
                        <label for="instance_id" class="dark:text-white">
                            ID da Instância
                        </label>
                        <input
                            type="text"
                            id="instance_id"
                            name="instance_id"
                            v-model="form.options.instance_id"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="ID da Instância"
                            required
                        />
                    </div>
                    <div class="grid sm:grid-cols-1">
                        <label for="instance_token" class="dark:text-white">
                            Token da Instância
                        </label>
                        <input
                            type="text"
                            id="instance_token"
                            name="instance_token"
                            v-model="form.options.instance_token"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Token da Instância"
                            required
                        />
                    </div>
                    <div class="grid sm:grid-cols-1">
                        <label for="is_main" class="dark:text-white flex items-center">
                            <input
                                type="checkbox"
                                id="is_main"
                                v-model="form.options.is_main"
                                class="mr-2"
                            />
                            Usar como API principal do WhatsApp
                        </label>
                    </div>
                </div>

                <div class="grid sm:grid-cols-11 mt-5 p-3">
                    <button
                        :disabled="this.loading"
                        type="submit"
                        :class="{ 'cursor-not-allowed': this.loading }"
                        class="mr-auto items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
                    >
                        Salvar
                    </button>
                    <button
                        @click.prevent="testConection"
                        :disabled="this.loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': this.loading }"
                        class="mr-auto items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-green-500 hover:bg-green-400 transition ease-in-out duration-150"
                    >
                        Testar conexão
                    </button>
                </div>
            </form>
        </div>
    </div>
    <hr />
    <div class="mt-10">
        <h3 class="text-lg leading-6 font-medium dark:text-white mb-5">
            Templates de mensagem do WhatsApp

            <button
                @click.prevent="openModal"
                :disabled="this.loading"
                type="button"
                :class="{ 'cursor-not-allowed': this.loading }"
                class="mr-auto items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-green-500 hover:bg-green-400 transition ease-in-out duration-150"
            >
                Novo
            </button>
        </h3>
        <table class="border-collapse table-auto w-full text-sm">
            <thead class="bg-white dark:bg-slate-800">
                <tr>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        ID
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Nome
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Evento
                    </th>
                    <th scope="col" class="p-3 dark:text-slate-200 text-left">
                        Ações
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white dark:bg-slate-800">
                <tr v-for="template in templates">
                    <td
                        class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                    >
                        {{ template.id }}
                    </td>
                    <td
                        class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                    >
                        {{ template.name }}
                    </td>
                    <td
                        class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                    >
                        {{ formatEvent(template.event) }}
                    </td>
                    <td
                        class="border-b border-slate-100 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-200"
                    >
                        <div>
                            <button
                                @click.prevent="openModalToEdit(template.id)"
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="inline-flex justify-center items-center ml-2 py-1 px-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
                            >
                                Editar
                            </button>
                            <button
                                @click.prevent="
                                    handleDeleteTemplate(template.id)
                                "
                                :disabled="loading"
                                type="button"
                                :class="{ 'cursor-not-allowed': loading }"
                                class="inline-flex justify-center items-center ml-2 py-1 px-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-red-500 hover:bg-red-400 transition ease-in-out duration-150"
                            >
                                Excluir
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <create-template-modal
        v-if="isModalOpen"
        @closeModal="closeModal"
        @fetchTemplates="fetchTemplates"
        :template-id="this.templateId"
    />
</template>

<script>
import { useToast } from "vue-toastification";
import SvgLoader from "../../components/layouts/SvgLoader.vue";
import CreateTemplateModal from "./modals/CreateTemplateModal.vue";

export default {
    components: { SvgLoader, CreateTemplateModal },
    data() {
        return {
            loading: false,
            service: null,
            form: {
                type: "z-api",
                options: {
                    instance_id: "",
                    instance_token: "",
                    is_main: false,
                },
            },
            templates: [],
            isModalOpen: false,
            templateId: null,
        };
    },
    mounted() {
        this.fetch("z-api");
        this.fetchTemplates();
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    methods: {
        fetch(type) {
            this.loading = true;
            axios
                .get(`/api/services/type/${type}`)
                .then((response) => {
                    if (Object.keys(response.data).length) {
                        if (response.data?.options) {
                            this.form.options = response.data?.options;
                        }
                        this.service = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => console.error(error));
        },
        fetchTemplates() {
            this.loading = true;

            axios
                .get(`/api/whatsapp-automations-templates/`)
                .then((response) => {
                    this.templates = response.data;
                    this.loading = false;
                })
                .catch((error) => console.error(error));
        },
        submit() {
            if (!this.service) {
                axios
                    .post(`/api/services/`, this.form)
                    .then(({ data }) => {
                        if (data) {
                            this.toast.success(
                                "Credenciais salvas com sucesso"
                            );
                            this.$router.push({
                                name: "services",
                            });
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        }
                    );
            } else {
                axios
                    .put(`/api/services/${this.service?.id}`, this.form)
                    .then(({ data }) => {
                        if (data) {
                            this.toast.success(
                                "Credenciais atualizadas com sucesso"
                            );
                            this.$router.push({
                                name: "services",
                            });
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        }
                    );
            }
        },
        testConection() {
            axios
                .get(
                    `https://api.z-api.io/instances/${this.form.options.instance_id}/token/${this.form.options.instance_token}/status`,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                        },
                        withCredentials: false,
                    }
                )
                .then((response) => {
                    if (
                        response.data.error &&
                        !String(response.data.error).includes(
                            "already connected"
                        )
                    ) {
                        this.toast.error(
                            "Falha na conexão, verifique as credenciais."
                        );
                    } else {
                        this.toast.success("Conexão bem sucedida.");
                    }
                })
                .catch((error) => {
                    if (error.message.includes("code 400")) {
                        this.toast.error(
                            "Falha na conexão, verifique as credenciais."
                        );
                    }
                });
        },
        formatEvent(event) {
            switch (event) {
                case "refused":
                    return "Compra recusada";
                case "expired":
                    return "Boleto atrasado";
                case "abandoned_cart":
                    return "Carrinho abandonado";
                default:
                    return "";
            }
        },
        closeModal() {
            this.isModalOpen = false;
            this.templateId = null;
        },
        openModal() {
            this.isModalOpen = true;
        },
        openModalToEdit(id) {
            this.isModalOpen = true;
            this.templateId = id;
        },
        handleDeleteTemplate(id) {
            if (confirm("Tem certeza que deseja excluir o template?")) {
                axios
                    .delete(`/api/whatsapp-automations-templates/${id}`)
                    .then(({ data }) => {
                        if (data) {
                            this.toast.success("Template excluído com sucesso");
                            this.fetchTemplates();
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        }
                    );
            }
        },
    },
};
</script>
